body {
  margin: 0 auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
}

.app {
  margin-top: 28px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 0;
}

.block {
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 140px;
  height: 140px;
  padding: 14px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.blockLink {
  text-decoration: none;
  display: block;
}

.blockLink:not(:last-of-type) {
  margin-right: 10px;
}

.block:hover {
  cursor: pointer;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15), 0 1px 2px 0 #6e9fb8;
}

.block:not(:last-of-type) {
  margin-right: 10px;
}

.blocks {
  margin-top: 36px;
  display: flex;
}

.img {
  margin: 0 auto;
}

.img img {
  width: 74px;
}

.welcomeTitle {
  margin-top: 44px;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
}

.typeTitle {
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
}

.type {
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
  margin-top: 13px;
}

.blockLink {
  display: block;
  text-decoration: none;
}

.blockLink:visited {
  color: #4a4a4a;
  text-decoration: none;
}

@media (min-width: 420px) {
  .app {
    margin-top: 40px;
  }

  .block {
    width: 169px;
    height: 237px;
    padding: 35px 20px 20px;
  }

  .blockLink:not(:last-of-type) {
    margin-right: 18px;
  }

  .welcomeTitle {
    font-size: 20px;
    margin-top: 52px;
    margin-bottom: 4px;
  }

  .typeTitle {
    font-size: 20px;
  }

  .blocks {
    margin-top: 40px;
  }

  .type {
    font-size: 16px;
    line-height: 22px;
    margin-top: 12px;
  }

  .img img {
    width: 130px;
  }
}

@media (min-width: 768px) {
  .app {
    margin-top: 50px;
  }

  .block {
    width: 262px;
    height: 313px;
    padding: 37px 30px 28px;
  }

  .blockLink:not(:last-of-type) {
    margin-right: 40px;
  }

  .welcomeTitle {
    font-size: 28px;
    margin-top: 60px;
    line-height: 36px;
    margin-bottom: 8px;
  }

  .typeTitle {
    font-size: 28px;
    line-height: 36px;
  }

  .blocks {
    margin-top: 36px;
  }

  .type {
    font-size: 22px;
    line-height: 30px;
  }

  .img img {
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .app {
    margin-top: 50px;
  }
}

@media (min-width: 1440px) {
  .app {
    margin-top: 28px;
  }
}

.errorRoot {
  margin-top: 25px;
  background: #fff;
}

.wrapper {
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  padding: 27px 21px 35px;
  width: 290px;
  height: 259px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
}

.link {
  display: inline-block;
  border-radius: 1px;
  outline: none;
  text-decoration: none;
  color: #3072c4;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #3072c4;
}

.link:visited {
  text-decoration: none;
  color: #3072c4;
}

.showcaseSad {
  background: url(/static/media/ShowcaseSad.2a70f6be.svg) no-repeat;
  background-size: contain;
  background-position-x: 50%;
  flex-grow: 8;
}

.content {
  flex-grow: 1;
}

.header {
  font-weight: 300;
  font-size: 18px;
  color: #333333;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 20px;
}

.description {
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  text-align: left;
  max-width: 424px;
}

.errorCode {
  color: #e0333c;
}

.error {
  font-size: 14px;
  color: #333333;
  text-align: left;
}

@media (min-width: 768px) {
  .errorRoot {
    margin-top: 33px;
  }

  .link {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 24px;
  }

  .showcaseSad {
    background-size: auto;
  }

  .header {
    font-size: 32px;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .description {
    font-size: 16px;
  }

  .error {
    font-size: 16px;
  }

  .wrapper {
    flex-direction: row;
    padding-top: 60px;
    width: 716px;
    height: 320px;
  }
}


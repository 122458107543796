.app {
  margin-top: 28px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 0;
}

.block {
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  width: 140px;
  height: 140px;
  padding: 14px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.blockLink {
  text-decoration: none;
  display: block;
}

.blockLink:not(:last-of-type) {
  margin-right: 10px;
}

.block:hover {
  cursor: pointer;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15), 0 1px 2px 0 #6e9fb8;
}

.block:not(:last-of-type) {
  margin-right: 10px;
}

.blocks {
  margin-top: 36px;
  display: flex;
}

.img {
  margin: 0 auto;
}

.img img {
  width: 74px;
}

.welcomeTitle {
  margin-top: 44px;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
}

.typeTitle {
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  letter-spacing: 0;
  text-align: center;
}

.type {
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
  margin-top: 13px;
}

.blockLink {
  display: block;
  text-decoration: none;
}

.blockLink:visited {
  color: #4a4a4a;
  text-decoration: none;
}

@media (min-width: 420px) {
  .app {
    margin-top: 40px;
  }

  .block {
    width: 169px;
    height: 237px;
    padding: 35px 20px 20px;
  }

  .blockLink:not(:last-of-type) {
    margin-right: 18px;
  }

  .welcomeTitle {
    font-size: 20px;
    margin-top: 52px;
    margin-bottom: 4px;
  }

  .typeTitle {
    font-size: 20px;
  }

  .blocks {
    margin-top: 40px;
  }

  .type {
    font-size: 16px;
    line-height: 22px;
    margin-top: 12px;
  }

  .img img {
    width: 130px;
  }
}

@media (min-width: 768px) {
  .app {
    margin-top: 50px;
  }

  .block {
    width: 262px;
    height: 313px;
    padding: 37px 30px 28px;
  }

  .blockLink:not(:last-of-type) {
    margin-right: 40px;
  }

  .welcomeTitle {
    font-size: 28px;
    margin-top: 60px;
    line-height: 36px;
    margin-bottom: 8px;
  }

  .typeTitle {
    font-size: 28px;
    line-height: 36px;
  }

  .blocks {
    margin-top: 36px;
  }

  .type {
    font-size: 22px;
    line-height: 30px;
  }

  .img img {
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .app {
    margin-top: 50px;
  }
}

@media (min-width: 1440px) {
  .app {
    margin-top: 28px;
  }
}

.errorRoot {
  margin-top: 25px;
  background: #fff;
}

.wrapper {
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  padding: 27px 21px 35px;
  width: 290px;
  height: 259px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
}

.link {
  display: inline-block;
  border-radius: 1px;
  outline: none;
  text-decoration: none;
  color: #3072c4;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #3072c4;
}

.link:visited {
  text-decoration: none;
  color: #3072c4;
}

.showcaseSad {
  background: url("./images/ShowcaseSad.svg") no-repeat;
  background-size: contain;
  background-position-x: 50%;
  flex-grow: 8;
}

.content {
  flex-grow: 1;
}

.header {
  font-weight: 300;
  font-size: 18px;
  color: #333333;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 20px;
}

.description {
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  text-align: left;
  max-width: 424px;
}

.errorCode {
  color: #e0333c;
}

.error {
  font-size: 14px;
  color: #333333;
  text-align: left;
}

@media (min-width: 768px) {
  .errorRoot {
    margin-top: 33px;
  }

  .link {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 24px;
  }

  .showcaseSad {
    background-size: auto;
  }

  .header {
    font-size: 32px;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .description {
    font-size: 16px;
  }

  .error {
    font-size: 16px;
  }

  .wrapper {
    flex-direction: row;
    padding-top: 60px;
    width: 716px;
    height: 320px;
  }
}
